<template>
  <div>
    <h1>Contact</h1>
    <p>
      Pour toute question ou appréciation, ce formulaire vous permet de
      contacter l'auteur, Denis Jarrige.
    </p>
    <form method="post" action="mail.php">
      <v-row class="align-center">
        <v-col cols="5">
          <v-text-field
            required
            filled
            hideDetails
            name="from"
            label="Votre courriel"
            color="primary"
          >
          </v-text-field>
        </v-col>
        <VSpacer />
        <v-col cols="auto">
          <v-btn rounded color="primary" type="submit" @submit="sent">
            Envoyer
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            required
            filled
            hideDetails
            name="message"
            label="Votre message"
            color="primary"
            rows="7"
          />
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
export default {
  methods: {
    sent() {
      alert("Message sent !");
    },
  },
};
</script>
